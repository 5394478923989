import { useEffect, useRef, useState } from "react";
import { Popover } from "@headlessui/react";
import clsx from "clsx";

function MenuIcon({
  open,
  ...props
}: React.ComponentPropsWithoutRef<"svg"> & {
  open: boolean;
}) {
  return (
    <svg
      aria-hidden="true"
      fill="none"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        d={open ? "M17 7 7 17M7 7l10 10" : "m15 16-3 3-3-3M15 8l-3-3-3 3"}
      />
    </svg>
  );
}

interface SectionNavNavigationProps {
  sectionIds: string[];
}

export function SectionNavNavigation({
  sectionIds,
}: SectionNavNavigationProps) {
  let navBarRef = useRef<React.ElementRef<"div">>(null);
  let [activeIndex, setActiveIndex] = useState<number | null>(null);
  let mobileActiveIndex = activeIndex === null ? 0 : activeIndex;

  useEffect(() => {
    const updateActiveIndex = () => {
      if (!navBarRef.current) {
        return;
      }

      let newActiveIndex = null;
      let elements = sectionIds
        .map((id) => document.getElementById(id))
        .filter((el): el is HTMLElement => el !== null);
      let bodyRect = document.body.getBoundingClientRect();
      let offset = bodyRect.top + navBarRef.current.offsetHeight + 1;

      elements.forEach((element, index) => {
        if (window.scrollY >= element.getBoundingClientRect().top - offset) {
          newActiveIndex = index;
        } else {
          return false;
        }
      });

      setActiveIndex(newActiveIndex);
    };

    updateActiveIndex();

    window.addEventListener("resize", updateActiveIndex);
    window.addEventListener("scroll", updateActiveIndex, { passive: true });

    return () => {
      window.removeEventListener("resize", updateActiveIndex);
      window.removeEventListener("scroll", updateActiveIndex);
    };
  }, [navBarRef, setActiveIndex, sectionIds]);

  return (
    <div ref={navBarRef} className="sticky top-0 z-50">
      {/* TODO: Update to shadcn */}
      <Popover className="sm:hidden">
        {({ open }) => (
          <>
            <div
              className={clsx(
                "relative flex items-center px-4 py-3",
                !open &&
                  "bg-neutral-800/90 shadow-sm [@supports(backdrop-filter:blur(0))]:bg-neutral-800/80 [@supports(backdrop-filter:blur(0))]:backdrop-blur"
              )}
            >
              {!open && (
                <>
                  <span
                    aria-hidden="true"
                    className="font-mono text-sm text-themecolor"
                  >
                    {(mobileActiveIndex + 1).toString().padStart(2, "0")}
                  </span>
                  <span className="ml-4 text-base capitalize font-medium text-white">
                    {sectionIds[mobileActiveIndex]}
                  </span>
                </>
              )}
              <Popover.Button
                className={clsx(
                  "-mr-1 ml-auto flex h-8 w-8 items-center justify-center",
                  open && "relative z-10"
                )}
                aria-label="Toggle navigation menu"
              >
                {!open && (
                  <>
                    {/* Increase hit area */}
                    <span className="absolute inset-0" />
                  </>
                )}
                <MenuIcon open={open} className="h-6 w-6 stroke-slate-700" />
              </Popover.Button>
            </div>
            <Popover.Panel className="absolute inset-x-0 top-0 bg-neutral-800/90 py-3.5 shadow-sm [@supports(backdrop-filter:blur(0))]:bg-neutral-800/80 [@supports(backdrop-filter:blur(0))]:backdrop-blur">
              {sectionIds.map((id, sectionIndex) => (
                <Popover.Button
                  as="a"
                  key={id}
                  href={`#${id}`}
                  className="flex items-center px-4 py-1.5"
                >
                  <span
                    aria-hidden="true"
                    className="font-mono text-sm text-themecolor"
                  >
                    {(sectionIndex + 1).toString().padStart(2, "0")}
                  </span>
                  <span className="capitalize ml-4 text-base font-medium text-white">
                    {id}
                  </span>
                </Popover.Button>
              ))}
            </Popover.Panel>
            <div className="absolute inset-x-0 bottom-full z-10 h-4 bg-neutral-600" />
          </>
        )}
      </Popover>
      <div className="hidden sm:flex sm:h-32 sm:justify-center sm:border-b sm:border-neutral-600 sm:bg-neutral-800/90 sm:[@supports(backdrop-filter:blur(0))]:bg-neutral-800/80 sm:[@supports(backdrop-filter:blur(0))]:backdrop-blur-[3px]">
        <ol
          role="list"
          className="mb-[-2px] grid auto-cols-[minmax(0,15rem)] grid-flow-col text-base font-medium text-white [counter-reset:section]"
        >
          {sectionIds.map((id, sectionIndex) => (
            <li key={id} className="flex [counter-increment:section]">
              <a
                href={`#${id}`}
                className={clsx(
                  "flex w-full capitalize flex-col items-center justify-center border-b-2 before:mb-2 before:font-mono before:text-sm before:content-[counter(section,decimal-leading-zero)]",
                  sectionIndex === activeIndex
                    ? "border-themecolor bg-neutral-900/50 text-themecolor before:text-themecolor"
                    : "border-transparent before:text-white hover:bg-neutral-900 hover:before:text-white"
                )}
              >
                {id}
              </a>
            </li>
          ))}
        </ol>
      </div>
    </div>
  );
}
