import Image from "next/image";
import { TwoColumnContentFragment } from "__types__/graphql";
import { cn } from "@utils/classNames";

interface TwoColumnTextProps extends TwoColumnContentFragment {}

export function TwoColumnText({
  headline,
  simpleRichText,
  teaser,
  image,
  contentGrid,
  componentSettings,
}: TwoColumnTextProps) {
  const hideOnMobile = componentSettings?.[0]?.hideOnMobile ?? false;
  const featuredImage = image && image[0];

  return (
    <div
      className={cn(
        "px-4 py-20 bg-[#0E2A46]",
        hideOnMobile && "hidden sm:block"
      )}
    >
      <div className="max-w-6xl mx-auto">
        <div className="flex flex-col md:flex-row space-x-0 md:space-x-10 space-y-10 md:space-y-0 items-start">
          {featuredImage && (
            <Image
              src={featuredImage.url!}
              height="200"
              width="200"
              alt={featuredImage.alt ?? ""}
              className="flex-shrink-0"
            />
          )}
          <div className="flex flex-col space-y-2">
            <h2 className="font-extrabold text-xl md:text-3xl text-white">
              {headline}
            </h2>
            {simpleRichText && (
              <div
                className="mt-4 text-white leading-relaxed"
                dangerouslySetInnerHTML={{ __html: simpleRichText }}
              ></div>
            )}
          </div>
        </div>
        <div className="h-[2px] bg-white my-20 w-full" />
        <h2 className="font-extrabold text-xl md:text-3xl text-white text-center">
          {teaser}
        </h2>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-14 mt-10">
          {contentGrid.map((el, idx) => (
            <div key={`implementation-${idx}`}>
              <h2 className="font-extrabold text-xl md:text-2xl text-white text-center">
                {el?.cardHeading}
              </h2>
              <p className="mt-4 text-white leading-relaxed text-center">
                {el?.cardContent}
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
