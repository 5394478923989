import { cn } from "@utils/classNames";

export const BlueFlare = ({ className }: { className?: string }) => {
  return (
    <div className={cn("max-w-3xl mx-auto mb-40 relative", className)}>
      <div className="h-[4px] w-full mx-auto bg-gradient-to-r from-transparent via-themecolor to-transparent" />
      <div className="h-40 w-60 rounded-full bg-themecolor absolute right-1/2 blur-3xl opacity-[0.5] -top-20" />
      <div className="h-40 w-60 rounded-full bg-themecolor absolute left-1/2 blur-3xl opacity-[0.5] -top-20" />
      <div className="h-40 w-60 rounded-full bg-themecolor absolute left-1/2 transform -translate-x-1/2 blur-3xl opacity-[0.5] -top-20" />
    </div>
  );
};
