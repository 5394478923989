import { gql } from "@apollo/client";

const NEWS_FRAGMENT = gql`
  fragment NewsEntry on news_default_Entry {
    teaser
    newsLink
  }
`;

export const LATEST_NEWS_QUERY = gql`
  query GetNews($limit: Int) {
    entries(section: ["News"], limit: $limit, orderBy: "date", inReverse: true) {
      ... on news_default_Entry {
        newsLink
        teaser
        title
        postDate
      }
    }
  }
`;

export const NEWS_LISTING_QUERY = gql`
  ${NEWS_FRAGMENT}
  query GetNewsListing($limit: Int, $offset: Int, $newsTag: [QueryArgument]) {
    tags(group: "news") {
      id
      title
    }

    entries(
      section: "news"
      limit: $limit
      offset: $offset
      orderBy: "postDate desc"
      newsTag: $newsTag
    ) {
      id
      title
      slug
      dateCreated
      postDate
      ...NewsEntry
    }
  }
`;
