import { Button } from "@components/ui/Button";
import { cn } from "@utils/classNames";
import { Actions_BlockType } from "__types__/graphql"
import Link from "next/link";
import { ActionVariantOption } from "types/cms";

export const Actions = ({ actions, className = '' }: {
  actions: Actions_BlockType[];
  className?: string;
}) => {
  return (
    <div className={cn("flex flex-wrap gap-4", className)}>
      {actions.map(({ action, variant }) => (
        <Button
          asChild
          key={action!.url}
          variant={variant as ActionVariantOption ?? 'default'}
        >
          <Link href={action!.url as string} target={action!.target ?? '_self'}>
            {action!.customText}
          </Link>
        </Button>
      ))}
    </div>
  )
}