import { AccordionFragment } from "__types__/graphql";
import { Headline } from "../Headline";
import {
  Accordion as AccordionRoot,
  AccordionItem,
  AccordionTrigger,
  AccordionContent,
} from "../ui/Accordion";
import { cn } from "@utils/classNames";

interface AccordionProps extends AccordionFragment {
  headline: string;
  accordion: {
    accordionTitle: string;
    accordionContent: string;
  }[];
}
export function Accordion({
  headline,
  accordion,
  componentSettings,
}: AccordionProps) {
  const hideOnMobile = componentSettings?.[0]?.hideOnMobile ?? false;

  return (
    <div
      className={cn(
        "bg-neutral-900 my-24 md:my-32 px-4 md:px-6",
        hideOnMobile && "hidden sm:block"
      )}
    >
      <div className="max-w-4xl mx-auto px-4 md:px-8 border border-gray-700 p-10 rounded-xl">
        <Headline
          component="h2"
          value={headline ?? ""}
          className="text-2xl font-bold leading-10 tracking-tight text-white mb-6"
        />

        <AccordionRoot
          type="single"
          collapsible
          className="w-full [&>*+*]:mt-6"
        >
          {accordion.map((el, idx) => (
            <AccordionItem
              value={el.accordionTitle}
              key={idx}
              className="text-white text-lg px-4 md:px-6 border border-neutral-700 rounded-lg bg-neutral-800/50"
            >
              <AccordionTrigger>{el.accordionTitle}</AccordionTrigger>
              <AccordionContent className="border-t border-neutral-700 pt-4">
                <div
                  className="prose prose-invert xl:prose-lg mx-auto"
                  dangerouslySetInnerHTML={{
                    __html: el.accordionContent,
                  }}
                />
              </AccordionContent>
            </AccordionItem>
          ))}
        </AccordionRoot>
      </div>
    </div>
  );
}
