import { motion } from "framer-motion";
import Link from "next/link";
import Image from "next/image";
import { Blog_Default_Entry, CdaoVehicles_Default_Entry, Events_Default_Entry } from "__types__/graphql";
import dayjs from "dayjs";
import { cn } from "@utils/classNames";
import { truncatedText } from "@utils/truncateText";

interface CardWithImage {
  entry: Blog_Default_Entry | CdaoVehicles_Default_Entry | Events_Default_Entry;
  truncateText?: boolean;
}

export const CardWithImage = ({ entry, truncateText = false }: CardWithImage) => {
  const hasDate = 'date' in entry && entry.date
  const hasTeaser = 'teaser' in entry && entry.teaser
  let featuredImage = null;
  if ('image' in entry) {
    featuredImage = entry.image[0]
  }

  const prepath = entry.__typename === 'events_default_Entry'
    ? '/events/'
    : entry.__typename === 'blog_default_Entry'
      ? '/blog/'
      : entry.__typename === 'cdaoVehicles_default_Entry'
        ? '/cdao-vehicles/'
        : ''

  return (
    <motion.article
      whileHover={{
        y: -10,
      }}
      className="relative group mb-10 border rounded-xl border-neutral-800 group hover:bg-neutral-800"
    >
      <Link href={`${prepath}${entry.slug}`} className="absolute inset-0">
        <span className="sr-only">{entry.title}</span>
      </Link>

      {featuredImage && (
        <Image
          src={featuredImage.url!}
          width={500}
          height={300}
          alt={featuredImage.alt ?? entry.title!}
          className="rounded-tr-xl rounded-tl-xl shadow-lg shadow-themecolor/[0.1] w-full"
        />
      )}

      {hasDate && (
        <div className="py-1 px-4 text-center border border-neutral-700 bg-neutral-800 rounded-full text-neutral-100 font-bold w-fit mx-4 mt-4">
          {dayjs(entry.date).format("MMM DD, YYYY")}
        </div>
      )}

      <div className="p-4">
        <h1 className={cn(
          "font-extrabold text-neutral-200 group-hover:text-themecolor mt-2 block",
          hasDate ? 'text-xl' : 'text-lg'
        )}>
          {entry.title}
        </h1>

        {hasTeaser && !truncateText
          ? (
            <div className="mt-2 text-neutral-400">{entry.teaser}</div>
          ) : hasTeaser && (
            <div className=" text-neutral-400 mt-4">
              {truncatedText(entry.teaser as string, 200)}
            </div>
          )
        }
      </div>
    </motion.article>
  );
}