import { Headline } from "@components/Headline";
import { cn } from "@utils/classNames";
import { NumberedListFragment } from "__types__/graphql";

interface NumberedListProps extends NumberedListFragment {
  headline: string;
  contentGrid: {
    id: string;
    cardHeading: string;
    cardContent: string;
  }[];
}

export function NumberedList({
  headline,
  contentGrid,
  componentSettings,
}: NumberedListProps) {
  const hideOnMobile = componentSettings?.[0]?.hideOnMobile ?? false;

  return (
    <div className={cn("my-12 md:my-24", hideOnMobile && "hidden sm:block")}>
      <Headline
        component="h2"
        value={headline ?? ""}
        className="font-medium text-3xl md:text-4xl text-white text-center max-w-screen-lg mx-auto mb-4"
      />
      <div className="grid grid-cols-1 gap-20 max-w-screen-sm mx-auto pt-16">
        {contentGrid.map(({ cardHeading, cardContent }, idx) => (
          <div key={`opp-${idx}`} className="relative h-full">
            <div className="font-bold z-10 text-9xl text-neutral-800 absolute -left-6 -top-12">{`0${
              idx + 1
            }`}</div>
            <div className="relative  p-8   rounded-md  z-20 h-full">
              <h2 className="text-2xl font-bold text-themecolor  ">
                {cardHeading}
              </h2>
              <p className="text-neutral-400 text-base mt-4">{cardContent}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
