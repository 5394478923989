import { cn } from "@utils/classNames";
import { trpc } from "@utils/trpc";
import { StatsCalloutFragment } from "__types__/graphql";
import { useEffect, useState, useRef } from "react";
import CountUp from "react-countup";

export type Stat = {
  id: string;
  label: string | null;
  data: string | null;
};
interface StatisticsProps extends StatsCalloutFragment {
  stats: Stat[];
}
export const Statistics = ({
  copy,
  headline,
  stats = [],
  statsSource,
  componentSettings,
}: StatisticsProps) => {
  const [statsToUse, setStatsToUse] = useState<Stat[] | undefined>(stats);
  const { data: appStats } = trpc.stats.getAppStats.useQuery();
  const [isInView, setIsInView] = useState(false);
  const containerRef = useRef(null);
  const hideOnMobile = componentSettings?.[0]?.hideOnMobile ?? false;

  useEffect(() => {
    if (statsSource === "appStats") {
      setStatsToUse(appStats);
    }
  }, [statsSource, appStats]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsInView(entry.isIntersecting);
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0.3,
      }
    );
    if (containerRef.current) {
      observer.observe(containerRef.current);
    }
    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <div
      className={cn(
        "py-20 px-6 lg:px-8 | lg:py-28",
        hideOnMobile && "hidden sm:block"
      )}
      ref={containerRef}
    >
      <div className="mx-auto max-w-5xl">
        <div className="mx-auto max-w-2xl [&>*+*]:mt-16 lg:max-w-none">
          {(headline || copy) && (
            <div className="text-center">
              {headline && (
                <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">
                  {headline}
                </h2>
              )}
              {copy && (
                <p className="mt-4 text-lg leading-8 text-gray-300">{copy}</p>
              )}
            </div>
          )}
          <dl className="grid grid-cols-2 gap-0.5 overflow-hidden rounded-2xl text-center">
            {statsToUse &&
              statsToUse.map((stat, statIndex) => {
                const spanClass =
                  statIndex === statsToUse.length - 1 && statsToUse.length % 2
                    ? "col-span-2"
                    : "";
                return (
                  <div
                    key={stat.id}
                    className={`flex flex-col bg-white/5 p-8 ${spanClass}`}
                  >
                    <dt className="text-sm font-semibold leading-6 text-gray-300">
                      {stat.label}
                    </dt>
                    {isInView ? (
                      <CountUp
                        start={0}
                        end={parseFloat(stat.data ?? "0")}
                        delay={0}
                        decimals={
                          stat.data
                            ? parseFloat(stat.data).toString().split(".")[1]
                                ?.length || 0
                            : 0
                        }
                        suffix={stat.data?.replace(/[\d.-]/g, "")} // Extract non-numeric characters as suffix
                      >
                        {({ countUpRef }) => (
                          <dd
                            ref={countUpRef}
                            className="tabular-nums order-first text-3xl font-semibold tracking-tight text-white"
                          >
                            {/* Display only the numeric part here (automagic), suffix is handled by CountUp */}
                          </dd>
                        )}
                      </CountUp>
                    ) : (
                      <dd className="tabular-nums order-first text-3xl font-semibold tracking-tight text-white">
                        0
                      </dd>
                    )}
                  </div>
                );
              })}
          </dl>
        </div>
      </div>
    </div>
  );
};

Statistics.displayName = "Statistics";

export default Statistics;
