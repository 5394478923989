import { Events_Default_Entry } from "__types__/graphql";
import { CardWithImage } from "./CardWithImage";
import { cn } from "@utils/classNames";

interface EventGridProps {
  className?: string;
  entries: Events_Default_Entry[];
}

export function EventGrid({ className, entries }: EventGridProps) {
  return (
    <div className={cn("max-w-7xl mx-auto p-8 md:px-40 lg:px-8 grid grid-cols-1 lg:grid-cols-3 gap-10 relative z-40", className)}>
      {entries.length > 0 ? (
        entries.map((entry, i) => <CardWithImage key={`event-${entry.id}-${i}`} entry={entry} truncateText />)
      ) : (
        <div className="relative border border-neutral-700 p-8 rounded-md bg-neutral-800/50 z-10 h-full text-white md:col-span-2 lg:col-span-3 text-center">
          <p className="">No upcoming or recent events. Please check back later.</p>
        </div>
      )}
    </div>
  );
}
