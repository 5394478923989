import {
  Actions_BlockType,
  Components_ListingComponentsCallout_Entry,
  Events_Default_Entry,
  News_Default_Entry,
} from "__types__/graphql";
import { EventGrid } from "@components/EventGrid";
import { Actions } from "./Actions";
import { NewsListing } from "@components/NewsListing";
import { useEffect, useState } from "react";
import client from "@lib/apollo";
import { LATEST_EVENTS_QUERY } from "@lib/queries/events";
import { LATEST_NEWS_QUERY } from "@lib/queries/news";
import { cn } from "@utils/classNames";

interface ListingComponentsCalloutProps
  extends Components_ListingComponentsCallout_Entry {
  actions: Actions_BlockType[];
  eventsToFeature: Events_Default_Entry[];
  newsToFeature: News_Default_Entry[];
}

export const ListingComponentsCallout = ({
  actions,
  componentType,
  headline,
  eventsToFeature,
  newsToFeature,
  componentSettings,
}: ListingComponentsCalloutProps) => {
  const [entries, setEntries] = useState<
    Events_Default_Entry[] | News_Default_Entry[]
  >(componentType === "events" ? eventsToFeature : newsToFeature);
  const hideOnMobile = componentSettings?.[0]?.hideOnMobile ?? false;

  useEffect(() => {
    const fetchEvents = async () => {
      return await client
        .query({
          query: LATEST_EVENTS_QUERY,
          variables: {
            limit: 6,
          },
        })
        .then((res) => {
          setEntries((prev) => [
            ...prev,
            ...(res.data.entries
              .filter((entry: any) => !(entry in entries))
              .slice(0, 3 - entries.length) ?? []),
          ]);
        })
        .catch((err) => {
          throw err;
        });
    };
    const fetchNews = async () => {
      const GET_NEWS = LATEST_NEWS_QUERY;
      return await client
        .query({
          query: GET_NEWS,
          variables: {
            limit: 6,
          },
        })
        .then((res) => {
          setEntries((prev) => [
            ...prev,
            ...(res.data.entries
              .filter((entry: any) => !(entry in entries))
              .slice(0, 3 - entries.length) ?? []),
          ]);
        })
        .catch((err) => {
          throw err;
        });
    };

    if (entries.length < 3) {
      switch (componentType) {
        case "events":
          fetchEvents().catch((err) => {
            console.error(err);
          });
          break;
        case "news":
          fetchNews().catch((err) => {
            console.error(err);
          });
          break;
        default:
          break;
      }
    }
  }, [entries]);

  return (
    <div className={cn("pt-10", hideOnMobile && "hidden sm:block")}>
      <header className="text-center flex flex-col md:flex-row justify-center items-center gap-x-10 gap-y-4">
        <h2 className="max-w-2xl text-3xl font-bold tracking-tight text-white sm:text-4xl">
          {headline}
        </h2>
        {actions.length > 0 && <Actions actions={actions} />}
      </header>

      {componentType === "events" ? (
        <EventGrid
          entries={entries as Events_Default_Entry[]}
          className="mt-10"
        />
      ) : (
        <NewsListing
          entries={entries as News_Default_Entry[]}
          showTags={false}
          className="mt-10"
        />
      )}
    </div>
  );
};
