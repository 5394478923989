import { VideoGridWithSectionsFragment } from "__types__/graphql";
import { VideoGrid } from "./VideoGrid";
import { cn } from "@utils/classNames";

interface VideoGridWithSectionsProps extends VideoGridWithSectionsFragment {}

export const VideoGridWithSections = ({
  videoGridWithSections,
  componentSettings,
}: VideoGridWithSectionsProps) => {
  if (!videoGridWithSections.length) {
    return null;
  }
  const hideOnMobile = componentSettings?.[0]?.hideOnMobile ?? false;

  return (
    <div className={cn("py-10", hideOnMobile && "hidden sm:block")}>
      {videoGridWithSections.map((section, idx) => (
        <section
          className="container mx-auto px-10 max-w-screen-xl"
          key={section?.sectionTitle! + idx}
        >
          <p className="text-2xl text-white font-bold">
            {section?.sectionTitle}
          </p>

          <hr className="mt-2 mb-8 opacity-30" />

          {/* neg margin to override explicit px- in VideoGrid */}
          <div className="-mx-8">
            {section?.videos && (
              <VideoGrid
                videoGrid={section?.videos.map((video) => ({
                  __typename: "videoGrid_TableRow",
                  videoEmbedUrl: video?.videoEmbedUrl,
                  featured: video?.featured,
                  displayedDescription: video?.displayedDescription,
                }))}
              />
            )}
          </div>
        </section>
      ))}
    </div>
  );
};
