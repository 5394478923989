import { z } from "zod";
import { useForm } from "@mantine/form";
import { Input } from "./ui/Input";
import { Button } from "./ui/Button";
import { Label } from "./ui/Label";
import toast from "react-hot-toast";
import { useUser } from "@clerk/nextjs";
import { trpc } from "@utils/trpc";

const PlaybookDownloadSchema = z.object({
  name: z.string().min(1, "Name is required"),
  email: z.string().email("Invalid email").min(1, "Email is required"),
  organizationName: z.string().min(1, "Organization/Agency name is required"),
});

export default function PlaybookDownloadSection() {
  const user = useUser();
  const form = useForm({
    initialValues: {
      name: "",
      email: "",
      organizationName: "",
      fileDownloaded: "",
    },
  });
  const storePdfDownload = trpc.pdfDownloads.createPDFDownload.useMutation();

  const handleSubmit = async (values: {
    name: string;
    email: string;
    organizationName: string;
  }) => {
    const result = PlaybookDownloadSchema.safeParse(values);
    if (result.success) {
      try {
        storePdfDownload.mutate(
          {
            ...values,
            userId: user?.user?.id ?? null,
            fileDownloaded:
              "https://storage.tradewindai.com/pdfs/playbook-gapingvoid-tradewinds.pdf",
          },
          {
            onSuccess: () => {
              const link = document.createElement("a");
              link.href =
                "https://storage.tradewindai.com/pdfs/playbook-gapingvoid-tradewinds.pdf";
              link.setAttribute(
                "download",
                "playbook-gapingvoid-tradewinds.pdf"
              );
              link.setAttribute("target", "_blank");
              link.setAttribute("rel", "noreferrer");
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
            },
            onError: () => {
              toast.error(
                "There was an error downloading the PDF, please try again."
              );
            },
          }
        );
      } catch (error) {
        console.error("Download error:", error);
        toast.error("Failed to download the file. Please try again.");
      }
    } else {
      toast.error(
        "Please fill in all fields properly then try again.\n\nIf the issue persists, contact us."
      );
    }
  };

  return (
    <div className="flex flex-col mb-20 max-w-6xl mx-auto | md:flex-row md:items-center | lg:mb-0">
      <div className="w-full md:w-1/2 p-10">
        <img
          src="https://storage.tradewindai.com/images/playbook-screenshot-slide1.png"
          alt="Featured"
          className="rounded-xl"
        />
      </div>
      <div className="w-full md:w-1/2 p-10 flex flex-col gap-6">
        <div className="text-white flex flex-col gap-4">
          <p className="text-2xl text-gray-300 font-medium">
            Download the Tradewinds Guide to Faster and Smarter Contracting!
          </p>
          <p className="text-gray-300">
            Inside you&apos;ll find cheat codes and thinking models to level up
            your game and excel in the world of acquisitions.
          </p>
        </div>
        <form
          onSubmit={form.onSubmit(handleSubmit)}
          className="flex flex-col gap-4"
        >
          <div>
            <Label className="text-white">Your Name</Label>
            <Input variant={`dark`} required {...form.getInputProps("name")} />
          </div>
          <div>
            <Label className="text-white">Email</Label>
            <Input variant={`dark`} required {...form.getInputProps("email")} />
          </div>
          <div>
            <Label className="text-white">Organization/Agency Name</Label>
            <Input
              variant={`dark`}
              required
              {...form.getInputProps("organizationName")}
            />
          </div>
          <Button type="submit" className="mt-4">
            View and Download PDF
          </Button>
        </form>
      </div>
    </div>
  );
}
