import { MiniBanner } from "@components/cms/MiniBanner";
import { MainHero } from "@components/cms/MainHero";
import { BasicHero } from "@components/cms/BasicHero";
import { ContentCardGrid } from "@components/cms/ContentCardGrid";
import { RichText } from "@components/cms/RichText";
import { Accordion } from "@components/cms/Accordion";
import { Banner } from "@components/cms/Banner";
import { LogoGrid } from "@components/cms/LogoGrid";
import { VideoHero } from "@components/cms/VideoHero";
import { VideoGrid } from "@components/cms/VideoGrid";
import { TwoColumnText } from "@components/cms/TwoColumnText";
import { FeatureGrid } from "@components/cms/FeatureGrid";
import { OpportunityGrid } from "@components/cms/OpportunityGrid";
import { TwoColumnBanner } from "@components/cms/TwoColumnBanner";
import { NumberedList } from "@components/cms/NumberedList";
import { Statistics } from "@components/cms/Statistics";
import { SectionNav } from "@components/cms/SectionNav";
import { FeatureSectionCentered } from "@components/cms/FeatureSectionCentered";
import { FeatureSectionWithQuote } from "@components/cms/FeatureSectionWithQuote";
import { BoxCalloutWithCards } from "@components/cms/BoxCalloutWithCards";
import { ListingComponentsCallout } from "@components/cms/ListingComponentsCallout";
import { VideoGridWithSections } from "@components/cms/VideoGridWithSections";
import PlaybookDownloadSection from "@components/PlaybookDownloadSection";

export const PageBuilderComponentMap = new Map<string, any>([
  ["accordion", Accordion],
  ["basicHero", BasicHero],
  ["banner", Banner],
  ["mainHero", MainHero],
  ["miniBanner", MiniBanner],
  ["richText", RichText],
  ["contentCardGrid", ContentCardGrid],
  ["iconCardGrid", FeatureGrid],
  ["opportunityGrid", OpportunityGrid],
  ["logoGrid", LogoGrid],
  ["statsCallout", Statistics],
  ["videoHero", VideoHero],
  ["videoGrid", VideoGrid],
  ["videoGridWithSections", VideoGridWithSections],
  ["twoColumnText", TwoColumnText],
  ["twoColumnBanner", TwoColumnBanner],
  ["numberedList", NumberedList],
  ["sectionNav", SectionNav],
  ["featureSectionCentered", FeatureSectionCentered],
  ["featureSectionWithQuote", FeatureSectionWithQuote],
  ["boxCalloutWithCards", BoxCalloutWithCards],
  ["listingComponentsCallout", ListingComponentsCallout],
  ["gapingvoidPlaybookDownloadSection", PlaybookDownloadSection],
]);

const Missing = (type: string) => {
  return () => (
    <div className="invert my-10 py-2 text-center border border-black border-dashed">
      Missing component {type}
    </div>
  );
};

export function getPageComponent(type: string) {
  const Component = PageBuilderComponentMap.get(type) ?? Missing(type);
  return Component;
}
