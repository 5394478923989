import { BasicHeroFragment } from "__types__/graphql";
import { Headline } from "../Headline";

interface BasicHeroProps extends BasicHeroFragment {
  headline: string;
  simpleRichText: string;
}

export function BasicHero({ headline, simpleRichText }: BasicHeroProps) {
  return (
    <div className="pt-12 md:pt-20 overflow-hidden relative px-8 max-w-screen-lg mx-auto">
      <div className="relative z-10">
        <Headline
          component="h1"
          value={headline}
          className="font-extrabold text-3xl md:text-6xl text-white text-center mb-4"
        />
        <div
          className="font-normal text-lg text-center max-w-3xl mx-auto mt-2 text-white/80 [&>*+*]:mt-4"
          dangerouslySetInnerHTML={{ __html: simpleRichText }}
        ></div>
      </div>
    </div>
  );
}
