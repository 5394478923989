import { Actions_BlockType, RichTextFragment } from "__types__/graphql";
import { Headline } from "../Headline";
import { Actions } from "./Actions";
import { cn } from "@utils/classNames";

interface RichTextProps extends RichTextFragment {
  richContent: string;
  actions: Actions_BlockType[];
}
export function RichText({
  headline,
  richContent,
  container = "fullWidth",
  actions,
  image,
  videoEmbedUrl,
  componentSettings,
}: RichTextProps) {
  const hideOnMobile = componentSettings?.[0]?.hideOnMobile ?? false;
  const finalImage = image && image.length ? image[0] : null;
  if (container === "prose") {
    return (
      <div
        className={cn(
          "text-white px-4 my-12 md:my-24 relative z-10",
          hideOnMobile && "hidden sm:block"
        )}
      >
        <div className="max-w-3xl mx-auto">
          {headline && (
            <Headline
              component="h2"
              value={headline}
              className="font-medium text-3xl md:text-4xl text-white text-left mb-4"
            />
          )}
          <div
            className="prose prose-invert lg:prose-lg prose-headings:font-medium"
            dangerouslySetInnerHTML={{ __html: richContent }}
          />
        </div>

        {(finalImage || videoEmbedUrl) && (
          <div className="max-w-screen-lg mx-auto mt-12">
            <div className="relative z-10">
              <div className="aspect-w-16 aspect-h-9">
                {videoEmbedUrl && (
                  <iframe
                    className="absolute inset-0 w-full h-full rounded-xl"
                    src={videoEmbedUrl}
                    title="Tradewinds Solutions Marketplace"
                    allowFullScreen
                  />
                )}
                {finalImage && (
                  <img
                    className="absolute inset-0 w-full h-full rounded-xl"
                    src={finalImage.url!}
                    alt={finalImage.alt ?? ""}
                  />
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }

  return (
    <div
      className={cn(
        "text-white px-4 grid grid-cols-1 gap-10 max-w-screen-xl mx-auto my-12 md:my-24 relative z-10",
        (finalImage || videoEmbedUrl) && "lg:grid-cols-2",
        hideOnMobile && "hidden sm:grid"
      )}
    >
      <div className="[&>*+*]:mt-6">
        {headline && (
          <Headline
            component="h2"
            value={headline}
            className="max-w-screen-sm lg:max-w-screen-md mx-auto font-medium text-3xl md:text-4xl text-white text-left mb-4"
          />
        )}

        <div
          className="mx-auto prose prose-invert lg:prose-lg prose-headings:font-medium"
          dangerouslySetInnerHTML={{ __html: richContent }}
        />

        {actions && (
          <Actions actions={actions} className="flex md:justify-center" />
        )}
      </div>

      {(finalImage || videoEmbedUrl) && (
        <div>
          <div className="relative z-10">
            <div className="aspect-w-16 aspect-h-9">
              {videoEmbedUrl && (
                <iframe
                  className="absolute inset-0 w-full h-full rounded-xl"
                  src={videoEmbedUrl}
                  title="Tradewinds Solutions Marketplace"
                  allowFullScreen
                />
              )}
              {finalImage && (
                <img
                  className="absolute inset-0 w-full h-full rounded-xl"
                  src={finalImage.url!}
                  alt={finalImage.alt ?? ""}
                />
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
