import Image from "next/image";
import React from "react";
import { Cloudflare_Asset, LogoGridFragment } from "__types__/graphql";
import { Headline } from "../Headline";
import { cn } from "@utils/classNames";

interface LogoGridProps extends LogoGridFragment {
  organizationLogos: Cloudflare_Asset[];
}

export const LogoGrid = ({
  headline,
  simpleRichText,
  organizationLogos,
  componentSettings,
}: LogoGridProps) => {
  const hideOnMobile = componentSettings?.[0]?.hideOnMobile ?? false;

  return (
    // <div
    //   className=" bg-[url('/images/elements/abstract-3.svg')] bg-cover   relative py-40 md:py-48"
    //   style={{ backgroundPosition: "0 25%" }}
    // >
    //   <h2 className="font-extrabold text-3xl text-black text-center  px-4">
    //     {headline}
    //   </h2>
    //   {simpleRichText && (
    //     <div
    //       className="font-normal text-base text-black text-center max-w-2xl mx-auto mt-2  px-4"
    //       dangerouslySetInnerHTML={{ __html: simpleRichText }}
    //     ></div>
    //   )}

    //   <div className="grid grid-cols-2 md:grid-cols-3 gap-8 mx-auto max-w-4xl mt-8 relative ">
    //     {organizationLogos.map((logo, idx) => (
    //       <Image
    //         src={logo?.url!}
    //         alt="logo"
    //         height="200"
    //         width="200"
    //         key={logo?.id}
    //         className="mx-auto"
    //       />
    //     ))}
    //   </div>
    // </div>

    <div
      className={cn(
        "bg-neutral-900 px-8 md:py-40 py-20 relative overflow-hidden",
        hideOnMobile && "hidden sm:block"
      )}
    >
      <svg
        className="absolute inset-0 z-10 h-full w-full stroke-white/10 [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)]"
        aria-hidden="true"
      >
        <defs>
          <pattern
            id="983e3e4c-de6d-4c3f-8d64-b9761d1534cc"
            width={200}
            height={200}
            x="50%"
            y={-1}
            patternUnits="userSpaceOnUse"
          >
            <path d="M.5 200V.5H200" fill="none" />
          </pattern>
        </defs>
        <svg x="50%" y={-1} className="overflow-visible fill-gray-800/20">
          <path
            d="M-200 0h201v201h-201Z M600 0h201v201h-201Z M-400 600h201v201h-201Z M200 800h201v201h-201Z"
            strokeWidth={0}
          />
        </svg>
        <rect
          width="100%"
          height="100%"
          strokeWidth={0}
          fill="url(#983e3e4c-de6d-4c3f-8d64-b9761d1534cc)"
        />
      </svg>
      <div
        className="absolute left-[calc(50%-4rem)] top-10 z-10 transform-gpu blur-3xl sm:left-[calc(50%-18rem)] lg:left-48 lg:top-[calc(50%-30rem)] xl:left-[calc(50%-24rem)]"
        aria-hidden="true"
      >
        <div
          className="aspect-[1108/632] w-[69.25rem] bg-gradient-to-r from-[#80caff] to-[#4f46e5] opacity-20"
          style={{
            clipPath:
              "polygon(73.6% 51.7%, 91.7% 11.8%, 100% 46.4%, 97.4% 82.2%, 92.5% 84.9%, 75.7% 64%, 55.3% 47.5%, 46.5% 49.4%, 45% 62.9%, 50.3% 87.2%, 21.3% 64.1%, 0.1% 100%, 5.4% 51.1%, 21.4% 63.9%, 58.9% 0.2%, 73.6% 51.7%)",
          }}
        />
      </div>
      <div className="relative max-w-5xl mx-auto z-20">
        <Headline
          component="h1"
          className="text-2xl md:text-4xl text-center font-bold  md:leading-[1.3] bg-clip-text text-transparent bg-gradient-to-b from-neutral-50 to-neutral-300"
          value={headline ?? ""}
        />

        {simpleRichText && (
          <div
            className="text-sm md:text-base  max-w-3xl mx-auto text-center leading-normal text-gray-400 mt-8"
            dangerouslySetInnerHTML={{ __html: simpleRichText }}
          ></div>
        )}
      </div>
      <div className="flex flex-row max-w-5xl mx-auto flex-wrap justify-center mt-8 relative ">
        {organizationLogos.map((logo, idx) => (
          <Image
            src={logo?.url!}
            alt="logo"
            height="200"
            width="200"
            key={idx}
            className="mx-8 my-8"
          />
        ))}
      </div>
    </div>
  );
};
