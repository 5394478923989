import { BlueFlare } from "@components/BlueFlare";
import { SectionNavNavigation } from "@components/SectionNavNavigation";
import { getPageComponent } from "@lib/pageBuilder";
import { SectionNavEntryFragment } from "__types__/graphql";

interface SectionNavProps extends SectionNavEntryFragment {}

export const SectionNav = ({
  headline,
  sectionNavSections,
  showFlare,
}: SectionNavProps) => {
  if (!sectionNavSections.length) return null;

  return (
    <>
      <div className="[&>*+*]:mt-20 mt-20 overflow-hidden">
        {showFlare && <BlueFlare />}
        <h2 className="text-white text-4xl text-center font-bold">
          {headline}
        </h2>
      </div>

      <div className="text-white">
        <SectionNavNavigation
          sectionIds={sectionNavSections?.map(
            (section) => section?.sectionId ?? ""
          )}
        />

        <div className="[&>*+*]:pt-20 [&>*]:scroll-mt-20">
          {sectionNavSections.map((block) => {
            const components = block?.components;
            if (!components?.length) return null;

            return (
              <section
                key={block?.sectionId}
                id={block?.sectionId!}
                className="flex flex-col items-center justify-center mx-auto"
              >
                {components?.map((comp) => {
                  if (!comp) {
                    return null;
                  }
                  const Component = getPageComponent(comp.typeHandle);
                  return <Component key={comp.id} {...comp} />;
                })}
              </section>
            );
          })}
        </div>
      </div>
    </>
  );
};
