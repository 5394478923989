import { Headline } from "@components/Headline";
import { cn } from "@utils/classNames";
import { TwoColumnBannerFragment } from "__types__/graphql";
import React from "react";

interface TwoColumnBannerProps extends TwoColumnBannerFragment {}

export const TwoColumnBanner = (props: TwoColumnBannerProps) => {
  const { richCopyTable, componentSettings } = props;
  const hideOnMobile = componentSettings?.[0]?.hideOnMobile ?? false;
  const className =
    richCopyTable.length > 1
      ? "grid grid-cols-1 md:grid-cols-2 gap-10 max-w-6xl"
      : "flex items-center justify-center max-w-screen-lg text-center";

  return (
    <div
      className={cn(
        "px-8 py-24 md:py-32 bg-neutral-800 mb-12 md:mb-24",
        hideOnMobile && "sm:block"
      )}
    >
      <div className={cn("mx-auto", className)}>
        {richCopyTable?.map((block, idx) => (
          <div key={idx}>
            <Headline
              component="h1"
              value={block?.headline!}
              className="text-4xl font-bold text-neutral-50"
            />
            <div
              className="prose prose-invert mt-10"
              dangerouslySetInnerHTML={{ __html: block?.richText ?? "" }}
            />
          </div>
        ))}
      </div>
    </div>
  );
};
