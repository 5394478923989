import { ContentCardGridFragment } from "__types__/graphql";
import { Headline } from "../Headline";
import { cn } from "@utils/classNames";
import Link from "next/link";

interface ContentCardGridProps extends ContentCardGridFragment {
  headline?: string;
  simpleRichText: string;
  contentGrid: {
    id: string;
    cardHeading: string;
    cardContent: string;
    cardAction: {
      url: string;
      ariaLabel: string;
      target: string;
      type: string;
    };
  }[];
  columnCount: number;
}

function finalColumnCount(count: number) {
  switch (count) {
    case 2:
      return "grid-cols-1 md:grid-cols-2 gap-4 md:gap-12 max-w-screen-lg";
    case 3:
      return "grid-cols-1 md:grid-cols-3 gap-4 md:gap-10 max-w-screen-xl";
    case 4:
      return "grid-cols-1 md:grid-cols-4 gap-4 md:gap-8 max-w-screen-xl";
    default:
      return "grid-cols-1 md:grid-cols-2 gap-4 md:gap-12 max-w-screen-lg";
  }
}

export function ContentCardGrid({
  headline,
  simpleRichText,
  contentGrid,
  showNumbers,
  columnCount,
  componentSettings,
}: ContentCardGridProps) {
  const cols = finalColumnCount(columnCount);
  const hideOnMobile = componentSettings?.[0]?.hideOnMobile ?? false;

  return (
    <div className={cn("my-24 md:my-32", hideOnMobile && "hidden sm:block")}>
      {headline && (
        <Headline
          component="h2"
          value={headline}
          className="font-medium text-3xl md:text-4xl text-white text-center max-w-screen-lg mx-auto mb-4"
        />
      )}
      <div className={cn("grid mx-auto pt-16 px-4", cols)}>
        {contentGrid.map(({ cardHeading, cardContent, cardAction }, idx) => {
          const Wrapper = ({ children }: { children: React.ReactNode }) => {
            if (cardAction.url) {
              return (
                <Link
                  href={cardAction.url}
                  target={cardAction.target}
                  className="relative h-full"
                >
                  {children}
                </Link>
              );
            } else {
              return <div className="relative h-full">{children}</div>;
            }
          };

          return (
            <Wrapper key={`opp-${idx}`}>
              {showNumbers && (
                <div className="font-bold z-20 text-8xl text-neutral-800 absolute right-4 -top-12">{`0${
                  idx + 1
                }`}</div>
              )}
              <div className="relative border border-neutral-700 p-8 rounded-md bg-neutral-800/50 z-10 h-full">
                {cardHeading && (
                  <h2 className="text-2xl font-bold text-themecolor mb-4">
                    {cardHeading}
                  </h2>
                )}
                <p className="text-neutral-400 text-base">{cardContent}</p>
              </div>
            </Wrapper>
          );
        })}
      </div>
    </div>
  );
}
