import {
  Actions_BlockType,
  Components_FeatureSectionCentered_Entry,
} from "__types__/graphql";
import { Actions } from "./Actions";
import { Headline } from "@components/Headline";
import { cn } from "@utils/classNames";

interface FeatureSectionCenteredProps
  extends Components_FeatureSectionCentered_Entry {
  actions: Actions_BlockType[];
}

export const FeatureSectionCentered = ({
  actions,
  simpleRichText,
  eyebrow,
  headline,
  image,
  componentSettings,
}: FeatureSectionCenteredProps) => {
  const hideOnMobile = componentSettings?.[0]?.hideOnMobile ?? false;
  const img = image[0] ?? null;

  return (
    <div className={cn("py-20", hideOnMobile && "hidden sm:block")}>
      <div className="mx-auto max-w-7xl px-6 lg:px-8 flex flex-col items-center gap-10">
        <div className="mx-auto max-w-2xl sm:text-center">
          {eyebrow && (
            <h2 className="text-base text-center font-semibold leading-7 text-themecolor">
              {eyebrow}
            </h2>
          )}
          {headline && (
            <Headline
              component="h2"
              value={headline}
              className="mt-2 text-3xl font-bold tracking-tight text-center text-white sm:text-4xl"
            />
          )}
          {simpleRichText && (
            <div
              className="mt-6 text-lg leading-8 text-gray-300"
              dangerouslySetInnerHTML={{ __html: simpleRichText }}
            ></div>
          )}
        </div>

        {actions.length > 0 && <Actions actions={actions} />}
      </div>
      <div className="relative z-10 overflow-hidden pt-16 px-10 xl:px-0">
        <div className="mx-auto max-w-6xl aspect-[16/9]">
          <img
            src={img?.url!}
            alt={img?.title!}
            // blur-[5px] border
            className="mb-[-12%] rounded-xl shadow-2xl shadow-white ring-1 ring-white/10 w-full h-full object-cover object-top border border-gray-700"
            width={img?.width!}
            height={img?.height!}
          />
          <div
            className="absolute -inset-x-20 bottom-0 bg-gradient-to-t from-[rgba(23,23,23,1)] h-[200px] lg:h-[500px]"
            aria-hidden="true"
          />
        </div>
      </div>
    </div>
  );
};
