import { cn } from "@utils/classNames";
import { VideoGridFragment } from "__types__/graphql";

interface VideoGridProps extends Partial<VideoGridFragment> {}

export function VideoGrid({
  videoGrid,
  simpleRichText,
  componentSettings,
}: VideoGridProps) {
  const hideOnMobile = componentSettings?.[0]?.hideOnMobile ?? false;
  const featuredVideo = videoGrid?.find((v) => v?.featured);
  const otherVideos = videoGrid?.filter(
    (v) => v?.videoEmbedUrl !== featuredVideo?.videoEmbedUrl
  );

  return (
    <div
      className={cn(
        "my-18 lg:my-24 [&>*+*]:mt-10 max-w-7xl container mx-auto px-6 lg:px-8",
        hideOnMobile && "hidden sm:block"
      )}
    >
      {simpleRichText && (
        <div className="max-w-4xl mx-auto text-center">
          <div
            className="max-w-full prose prose-invert"
            dangerouslySetInnerHTML={{ __html: simpleRichText }}
          ></div>
        </div>
      )}

      {featuredVideo && (
        <div className="aspect-[16/9] mx-auto rounded-xl overflow-hidden">
          <iframe
            src={featuredVideo?.videoEmbedUrl!}
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
            className="inset-0 w-full h-full"
          ></iframe>
        </div>
      )}

      {otherVideos && otherVideos.length > 0 && (
        <div className="grid grid-cols-1 md:grid-cols-3 gap-10 max-w-screen-xl mx-auto px-8 pt-8">
          {otherVideos?.map((video, idx) => (
            <div className="relative z-10" key={idx}>
              <div className="aspect-w-16 aspect-h-9">
                <iframe
                  src={video?.videoEmbedUrl!}
                  title="YouTube video player"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowFullScreen
                  className="absolute inset-0 w-full h-full rounded-xl"
                ></iframe>
              </div>
              {!!video?.displayedDescription && (
                <div className="text-white p-4 select-none whitespace-pre-wrap">
                  {video.displayedDescription}
                </div>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
