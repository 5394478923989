import { Button } from "@components/ui/Button";
import Image from "next/image";
import Link from "next/link";
import React from "react";
import { ActionFragment, BannerFragment } from "__types__/graphql";
import { Headline } from "../Headline";
import { cn } from "@utils/classNames";

interface BannerProps extends BannerFragment {
  actions: ActionFragment[];
}

export const Banner = ({
  headline,
  simpleRichText,
  theme = "light",
  image,
  actions,
  componentSettings,
}: BannerProps) => {
  const mainImage = image && image[0];
  const hideOnMobile = componentSettings?.[0]?.hideOnMobile ?? false;

  return (
    <div
      className={cn(
        "bg-neutral-900 w-full py-20",
        hideOnMobile && "hidden sm:block"
      )}
    >
      <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div className="relative isolate overflow-hidden bg-neutral-800 px-6 pt-16 shadow-2xl sm:rounded-3xl sm:px-16 md:pt-24 lg:flex lg:gap-x-20 lg:px-24 lg:pt-0">
          <svg
            viewBox="0 0 1024 1024"
            className="absolute left-1/2 top-1/2 -z-10 h-[64rem] w-[64rem] -translate-y-1/2 [mask-image:radial-gradient(closest-side,white,transparent)] sm:left-full sm:-ml-80 lg:left-1/2 lg:ml-0 lg:-translate-x-1/2 lg:translate-y-0"
            aria-hidden="true"
          >
            <circle
              cx={512}
              cy={512}
              r={512}
              fill="url(#759c1415-0410-454c-8f7c-9a820de03641)"
              fillOpacity="0.7"
            />
            <defs>
              <radialGradient id="759c1415-0410-454c-8f7c-9a820de03641">
                <stop stopColor="#0ea5e970" />
                <stop offset={1} stopColor="#0ea5e970" />
              </radialGradient>
            </defs>
          </svg>
          <div className="mx-auto max-w-md text-center lg:mx-0 lg:flex-auto lg:py-32 lg:text-left">
            <Headline
              component="h2"
              className="text-3xl font-bold tracking-tight text-white sm:text-4xl"
              value={headline ?? ""}
            />

            {simpleRichText && (
              <div
                className="mt-6 text-lg leading-8 text-gray-300"
                dangerouslySetInnerHTML={{ __html: simpleRichText }}
              ></div>
            )}

            <div className="mt-10 flex items-center justify-center gap-x-6 lg:justify-start">
              {actions.map(({ action }, idx) => (
                <Button
                  key={action!.url}
                  asChild
                  className={``}
                  variant={
                    idx !== 0
                      ? "ghost"
                      : theme === "light"
                      ? "light"
                      : "default"
                  }
                >
                  <Link
                    href={action!.url as string}
                    target={action!.target ?? "_self"}
                  >
                    {action!.customText}
                    {idx !== 0 && (
                      <span className="ml-2" aria-hidden="true">
                        →
                      </span>
                    )}
                  </Link>
                </Button>
              ))}
            </div>
          </div>
          <div className="relative mt-16 h-80 lg:mt-8">
            <img
              className="absolute left-0 top-0 w-[57rem] max-w-none rounded-md bg-white/5 ring-1 ring-white/10"
              src={mainImage?.url!}
              alt={mainImage?.alt ?? ""}
              width={1824}
              height={1080}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
