import { useQuery } from "@apollo/client";
import { Button } from "@components/ui/Button";
import { NEWS_LISTING_QUERY } from "@lib/queries/news";
import { IconLoader, IconX } from "@tabler/icons-react";
import { cn } from "@utils/classNames";
import { GetNewsListingQuery, News_Default_Entry } from "__types__/graphql";
import dayjs from "dayjs";
import { motion } from "framer-motion";
import Link from "next/link";
import { useState } from "react";
import { SeeMoreText } from "./SeeMoreText";

interface NewsListingProps {
  className?: string;
  entries: News_Default_Entry[];
  tags?: GetNewsListingQuery["tags"];
  showTags?: boolean;
}

export function NewsListing({ className, entries, tags, showTags = true }: NewsListingProps) {
  const [activeTag, setTag] = useState<string | null | undefined>();
  const { data, loading } = useQuery<GetNewsListingQuery>(NEWS_LISTING_QUERY, {
    variables: {
      limit: 100,
      offset: 0,
      newsTag: activeTag,
    },
  });

  const newsEntries = entries ?? (data?.entries as News_Default_Entry[]);

  return (
    <div className={className}>
      {showTags && tags && tags?.length > 0 && (
        <div className="mx-auto max-w-lg relative z-20 mb-8 px-8">
          <ul className="flex justify-center flex-wrap gap-4">
            {tags?.map((tag) => (
              <li key={tag!.id}>
                <button
                  className={cn(
                    "px-4 py-2 border flex items-center bg-neutral-800/50 rounded-full backdrop-blur-sm shadow-lg group relative text-white",
                    activeTag === tag!.id
                      ? "border-themecolor"
                      : "border-neutral-700"
                  )}
                  onClick={() => {
                    setTag(tag!.id);
                  }}
                >
                  {activeTag == tag!.id && loading && (
                    <IconLoader className="animate-spin mr-3" size={16} />
                  )}
                  {tag!.title}
                </button>
              </li>
            ))}
            {activeTag && (
              <li>
                <Button
                  onClick={() => {
                    setTag(undefined);
                  }}
                >
                  <IconX />
                  Clear
                </Button>
              </li>
            )}
          </ul>
        </div>
      )}
      <div className="max-w-7xl mx-auto p-8 md:px-40 lg:px-8 relative z-10 grid grid-cols-1 lg:grid-cols-3 gap-10">
        {newsEntries.map((entry, i) => (
          <motion.article
            className="p-8 border border-neutral-700 bg-neutral-800/50 rounded-lg backdrop-blur-sm shadow-lg group relative flex flex-col"
            key={`news-${entry.id}-${i}`}
            whileHover={{
              y: -10,
            }}
          >
            <Link
              href={entry.newsLink!}
              target="__blank"
              rel="nofollow"
              className="absolute inset-0"
            >
              <span className="sr-only">{entry.title}</span>
            </Link>
            <h1 className="font-extrabold text-xl text-neutral-50 group-hover:text-themecolor">
              {entry.title}
            </h1>
            <p className="text-neutral-400 my-4">{entry.teaser?.substring(0, 100)}&hellip;</p>
            <p className="mt-auto text-sm text-themecolor">
              Published on {dayjs(entry.postDate).format("MMM DD, YYYY")}
            </p>
          </motion.article>
        ))}
      </div>
    </div>
  );
}
