import { Button } from "@components/ui/Button";
import Link from "next/link";
import React from "react";
import {
  IconCardGridFragment,
  IconCardGrid_BlockType,
} from "__types__/graphql";
import { Headline } from "@components/Headline";
import Image from "next/image";
import { cn } from "@utils/classNames";

interface IconCardGridProps extends IconCardGridFragment {
  iconCardGrid: IconCardGrid_BlockType[];
}

export const FeatureGrid = (props: IconCardGridProps) => {
  const { headline, simpleRichText, iconCardGrid, componentSettings } = props;
  const hideOnMobile = componentSettings?.[0]?.hideOnMobile ?? false;

  return (
    <div
      className={cn(
        "bg-neutral-900 py-10 overflow-hidden relative px-8 [&>*+*]:mt-20",
        hideOnMobile && "hidden sm:block"
      )}
    >
      <div className="relative max-w-5xl mx-auto">
        <Headline
          component="h1"
          value={headline ?? ""}
          className="text-2xl md:text-4xl text-center font-bold  md:leading-[1.3] bg-clip-text text-transparent bg-gradient-to-b from-neutral-50 to-neutral-300"
        />
        {simpleRichText && (
          <div
            className="text-sm md:text-base  max-w-3xl mx-auto text-center leading-normal text-gray-400 mt-8"
            dangerouslySetInnerHTML={{ __html: simpleRichText }}
          />
        )}
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-10 max-w-5xl mx-auto">
        {iconCardGrid.map((card, idx) => {
          const iconImage = card.icon[0];
          return (
            <div key={`opp-${idx}`} className="relative h-full">
              {iconImage ? (
                <div className="flex items-center justify-center h-20 w-20 bg-themecolor rounded-full z-[1] absolute right-4 -top-12">
                  <Image
                    src={iconImage.url!}
                    width="50"
                    height="50"
                    alt="icon"
                    className="w-1/2 invert"
                  />
                </div>
              ) : (
                <div className="font-bold z-20 text-8xl text-neutral-800 absolute right-4 -top-12">{`0${
                  idx + 1
                }`}</div>
              )}
              <div className="relative z-[0] border border-neutral-700 p-8 rounded-md bg-neutral-800/50 h-full">
                <h2 className="text-2xl font-bold text-white  ">
                  {card.headline}
                </h2>
                <p className="text-neutral-400 text-base mt-4">
                  {card.subheadline}
                </p>

                <Button asChild className="mt-8">
                  <Link href={card.action?.url!}>
                    {card.action?.customText!}
                  </Link>
                </Button>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
