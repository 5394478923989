import clsx from "clsx";
import Link from "next/link";
import { ActionFragment, MiniBannerFragment } from "__types__/graphql";
import { cn } from "@utils/classNames";

interface MiniBannerProps extends MiniBannerFragment {
  actions: ActionFragment[];
  backgroundColor: string;
}
export function MiniBanner({
  headline,
  simpleRichText,
  actions,
  container,
  backgroundColor,
  componentSettings,
}: MiniBannerProps) {
  const hideOnMobile = componentSettings?.[0]?.hideOnMobile ?? false;
  /**
   * @todo
   * use the backgroundColor to determine text color based on lightness
   * use container (fullwidth, prose)
   */

  if (container === "prose") {
    return (
      <div
        className={cn(
          "relative max-w-6xl mx-auto rounded-md overflow-hidden mb-10 w-[90%]",
          hideOnMobile && "hidden sm:block"
        )}
      >
        <div
          className="absolute inset-0 "
          style={{
            backgroundImage:
              "linear-gradient(to bottom, rgba(0, 43, 72, 1), rgba(0, 43, 72, 1))",
          }}
        />
        <div className="absolute inset-0 bg-[url(/images/elements/dots.svg)] bg-cover bg-center opacity-[0.4]" />
        <div className="absolute inset-0 bg-[url(/images/elements/bg-logo.svg)] bg-cover bg-center" />
        <div className="relative  p-10 flex flex-col md:flex-row  items-start md:items-center md:space-x-8 space-y-8 md:space-y-0 justify-between">
          <div>
            <h1 className="text-2xl sm:text-3xl font-extrabold text-white flex flex-row space-x-4">
              {headline}
            </h1>
            <div
              className="text-white mt-4 prose prose-a:text-blue-600 prose-a:no-underline"
              dangerouslySetInnerHTML={{ __html: simpleRichText ?? "" }}
            ></div>
          </div>
          <div>
            {actions.map(({ action }) => (
              <Link
                key={action!.url}
                href={action!.url as string}
                target={action!.target ?? "_self"}
                className="text-white inline-flex items-center justify-center rounded-sm border-blue-500 bg-blue-500 hover:bg-[#002b48] hover:border-[#002b48]  text-base px-8 py-2 transition duration-150 shadow-[0_10px_20px_rgba(0,_0,_0,_.1),0_3px_6px_rgba(0,_0,_0,_.05)] hover:shadow-[rgba(0,_1,_0,_.2)_0_2px_8px] active:outline-none hover:opacity-80 border-2"
              >
                {action!.customText}
              </Link>
            ))}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div
      className={cn(
        "py-8 px-4 flex flex-col md:flex-row md:space-x-8 justify-center space-x-0 space-y-8 md:space-y-0 items-center",
        hideOnMobile && "hidden sm:flex"
      )}
      style={{
        backgroundColor,
      }}
    >
      <h2 className="text-base font-extrabold text-white">{headline}</h2>

      {actions.map(({ action }) => (
        <Link
          key={action!.url}
          href={action!.url as string}
          target={action!.target ?? "_self"}
          className="w-fit px-8 mt-4 block text-center py-3 hover:bg-[#0E2A46] bg-blue-500 rounded-sm text-white transition duration-200 "
        >
          {action!.customText}
        </Link>
      ))}
    </div>
  );
}
