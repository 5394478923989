import { gql } from "@apollo/client";

const EVENT_FRAGMENT = gql`
  fragment EventEntry on events_default_Entry {
    headline
    image {
      id
      url
      alt
    }
    teaser
    richContent
    date
    location
    eventType {
      id
      title
    }
    seo {
      title
      description
      advanced {
        canonical
        robots
      }
      social {
        twitter {
          image {
            id
          }
        }
        facebook {
          image {
            id
          }
        }
      }
    }
  }
`;

export const EVENT_PATHS = gql`
  query GetAllEvents {
    entries(section: "events", date: ">=now") {
      id
      title
      slug
      dateCreated
    }
  }
`;

export const LATEST_EVENTS_QUERY = gql`
  query GetEvents($limit: Int) {
    entries(section: ["Events"], limit: $limit, orderBy: "date", inReverse: true) {
      ... on events_default_Entry {
        title
        slug
        date
        teaser
        image {
          title
          alt
          url
        }
      }
    }
  }
`;

export const EVENT_LISTING_QUERY = gql`
  ${EVENT_FRAGMENT}

  query GetEventListing($limit: Int, $offset: Int, $date: [QueryArgument]) {
    entries(
      section: "events"
      limit: $limit
      offset: $offset
      date: $date
      orderBy: "date desc"
    ) {
      id
      title
      slug
      dateCreated
      ...EventEntry
    }
  }
`;

export const EVENT_QUERY = gql`
  ${EVENT_FRAGMENT}

  query GetEvent($slug: [String]) {
    entry(section: "events", slug: $slug) {
      id
      title
      slug
      dateCreated
      ...EventEntry
    }
  }
`;
