import { classNames, cn } from "@utils/classNames";
import {
  Actions_BlockType,
  Blockquote_BlockType,
  Components_FeatureSectionWithQuote_Entry,
} from "__types__/graphql";
import { Actions } from "./Actions";

interface FeatureSectionWithQuoteProps
  extends Components_FeatureSectionWithQuote_Entry {
  blockquote: Blockquote_BlockType[];
  actions: Actions_BlockType[];
}

export const FeatureSectionWithQuote = ({
  actions,
  blockquote,
  copySide,
  eyebrow,
  headline,
  image,
  simpleRichText,
  componentSettings,
}: FeatureSectionWithQuoteProps) => {
  const hideOnMobile = componentSettings?.[0]?.hideOnMobile ?? false;
  const img = image[0] ?? null;

  return (
    <div
      className={cn(
        "overflow-hidden w-full py-20 shrink-0",
        hideOnMobile && "hidden sm:block"
      )}
    >
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2 items-center">
          <div
            className={classNames(
              "lg:pr-4 lg:pt-4",
              copySide ? "lg:order-2" : "lg:order-1"
            )}
          >
            <div className="lg:max-w-lg">
              <h2 className="text-base font-semibold leading-7 text-themecolor">
                {eyebrow}
              </h2>
              <p className="mt-2 text-3xl font-bold tracking-tight text-white sm:text-4xl">
                {headline}
              </p>
              {simpleRichText && (
                <div
                  className="mt-6 text-lg leading-8 text-white/80 prose prose-invert max-w-full"
                  dangerouslySetInnerHTML={{ __html: simpleRichText }}
                ></div>
              )}
              {actions && <Actions actions={actions} className="mt-8" />}
              {blockquote.length > 0 &&
                blockquote.map((quote) => {
                  return (
                    <figure
                      key={quote.quote}
                      className="mt-16 border-l border-gray-200 pl-8 text-white/80"
                    >
                      <blockquote className="text-base leading-7">
                        <p>&ldquo;{quote.quote}&rdquo;</p>
                      </blockquote>
                      <figcaption className="mt-6 flex gap-x-4 text-sm leading-6">
                        {quote.photo && quote.photo[0]?.url && (
                          <img
                            src={quote.photo[0]?.url}
                            alt="Author Photo"
                            className="h-6 w-6 flex-none rounded-full object-cover"
                          />
                        )}
                        <div>
                          <span className="font-semibold text-white">
                            {quote.author}
                          </span>
                          {quote.organization && (
                            <span> – {quote.organization}</span>
                          )}
                        </div>
                      </figcaption>
                    </figure>
                  );
                })}
            </div>
          </div>
          <div
            className={classNames(
              "relative shadow-xl ring-1 ring-gray-400/10 border border-gray-700 rounded-xl overflow-hidden",
              "min-h-[450px] w-full",
              copySide ? "lg:order-1" : "lg:order-2"
            )}
          >
            <img
              src={img?.url!}
              alt={img?.alt!}
              className={cn(
                "absolute w-full h-full object-cover",
                copySide ? "right-0" : "left-0",
                "sm:w-[57rem]"
              )}
              width={img?.width!}
              height={img?.height!}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
