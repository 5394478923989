import Link from "next/link";
import React, { useEffect, useState } from "react";
import { BackgroundIllustration } from "../BackgroundIllustration";
import { motion } from "framer-motion";
import { Button, buttonVariants } from "@components/ui/Button";
import { ActionFragment, MainHeroFragment } from "__types__/graphql";
import { Headline } from "@components/Headline";
import { useWindowSize } from "usehooks-ts";
import { cn } from "@utils/classNames";

interface MainHeroProps extends MainHeroFragment {
  actions: ActionFragment[];
}

const pipeRegex = /\|([^|]+)\|/g;
const dollarRegex = /\$([^$]+)\$/g;

export const MainHero = (props: MainHeroProps) => {
  const [shouldAnimate, setShouldAnimate] = useState(false);
  const windowSize = useWindowSize();

  const { backgroundImage, headline, simpleRichText, actions } = props;
  const bgImage = backgroundImage[0];

  const decoratedHeadline = headline
    ?.replace(pipeRegex, '<span class="text-themecolor">$1</span>')
    ?.replace(
      dollarRegex,
      '<div class="relative inline-block bg-themecolor text-black">$1</div>'
    );

  useEffect(() => {
    setShouldAnimate(true);
  }, []);

  return (
    <div className=" flex py-28 md:py-40 lg:py-52 justify-center  bg-neutral-900  px-8  overflow-hidden relative">
      <BackgroundIllustration className="opacity-50 absolute left-1/2 top-4 h-[1026px] w-[1026px] -translate-x-1/3 stroke-neutral-300/70 [mask-image:linear-gradient(to_bottom,white_20%,transparent_45%)] sm:top-16 sm:-translate-x-1/2 lg:-top-16 lg:ml-12 xl:top-4 xl:ml-0" />

      <div className="max-w-6xl mx-auto relative">
        <div className="hidden md:block bg-themecolor h-[20rem] w-[40rem] left-1/2 transform -translate-x-1/2 absolute rounded-full blur-3xl opacity-[0.05]" />
        <div
          className={cn(
            "relative w-full flex flex-col md:flex-row justify-between gap-10"
          )}
        >
          <div className={cn("relative", bgImage?.url && "w-full md:w-1/2")}>
            <Headline
              value={headline ?? ""}
              component={motion.h1}
              initial={{ opacity: 0, y: 10 }}
              animate={
                shouldAnimate
                  ? {
                      opacity: 1,
                      y: 0,
                      transition: {
                        delay: 0.1,
                      },
                    }
                  : {}
              }
              transition={{ duration: 0.8 }}
              className={cn(
                "text-4xl text-center font-bold  md:leading-[1.3] bg-clip-text text-transparent bg-gradient-to-b from-neutral-50 to-neutral-300",
                bgImage?.url
                  ? "text-left text-5xl"
                  : "text-center sm:text-5xl lg:text-7xl"
              )}
            />
            {/* RICH TEXT */}
            <motion.div
              initial={{ opacity: 0, y: 10 }}
              animate={
                shouldAnimate
                  ? {
                      opacity: 1,
                      y: 0,
                      transition: {
                        delay: 0.3,
                        duration: 0.5,
                      },
                    }
                  : {}
              }
              transition={{ duration: 0.5 }}
              className={cn(
                "text-xl max-w-5xl mx-auto text-gray-300 mt-8 [&>*+*]:md:mt-3 [&>*>*>strong]:text-themecolor [&>*>strong]:text-themecolor",
                bgImage?.url
                  ? "text-left sm:text-2xl"
                  : "text-center sm:text-3xl lg:text-4xl"
              )}
              dangerouslySetInnerHTML={{
                __html:
                  simpleRichText?.replace(
                    pipeRegex,
                    '<span class="text-themecolor">$1</span>'
                  ) ?? "",
              }}
            ></motion.div>
            {/* ACTIONS */}
            <motion.div
              initial={{ opacity: 0, y: 10 }}
              animate={
                shouldAnimate
                  ? {
                      opacity: 1,
                      y: 0,
                      transition: {
                        delay: 0.5,
                        duration: 0.5,
                      },
                    }
                  : {}
              }
              transition={{ duration: 0.5 }}
              className="flex items-center justify-center gap-4 mt-8 relative"
            >
              {actions?.map(({ action, variant }) => (
                <Button
                  key={action!.url}
                  asChild
                  variant={
                    (variant as
                      | "default"
                      | "secondary"
                      | "outline"
                      | "ghost") ?? "default"
                  }
                  size={windowSize.width > 768 ? "xl" : "sm"}
                >
                  <Link href={action!.url!}>{action!.customText!}</Link>
                </Button>
              ))}
            </motion.div>
          </div>

          {bgImage && bgImage.url && (
            <motion.div
              className="w-full md:w-1/2 relative"
              initial={{ opacity: 0, x: 10 }}
              animate={
                shouldAnimate
                  ? {
                      opacity: 1,
                      x: 0,
                      transition: {
                        delay: 0.5,
                        duration: 0.5,
                      },
                    }
                  : {}
              }
              transition={{ duration: 0.5 }}
            >
              <img
                src={bgImage.url}
                alt={bgImage.alt ?? "Education Center hero image."}
                style={{
                  clipPath: `url(#hero-clip)`,
                }}
              />

              {/* HERO CLIP PATH */}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 684 638"
                fill="none"
                className="sr-only"
              >
                <defs>
                  <clipPath id="hero-clip" clipPathUnits="objectBoundingBox">
                    <path
                      d="M0.0175 0.3715C-0.0200 0.4484 0.0153 0.5095 0.0395 0.5378C0.1450 0.6614 0.3642 0.9164 0.3965 0.9547C0.4288 0.9930 0.4706 1.0005 0.4872 1H0.8696C0.9691 1 0.9985 0.9092 1 0.8653V0.3197C1 0.2856 0.9860 0.2548 0.9791 0.2439C0.9479 0.1958 0.8782 0.0899 0.8530 0.0518C0.8278 0.0137 0.7799 -0.0018 0.7593 -0.0029H0.2971C0.2291 -0.0029 0.1899 0.0483 0.1784 0.0721C0.1257 0.1694 0.0204 0.3658 0.0175 0.3715Z"
                      fill="#D9D9D9"
                    />
                  </clipPath>
                </defs>
              </svg>
            </motion.div>
          )}
        </div>
      </div>
    </div>
  );
};
