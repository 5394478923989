import React from "react";
import { VideoHeroFragment } from "__types__/graphql";
import { Headline } from "../Headline";

interface VideoHeroProps extends VideoHeroFragment {}

export const VideoHero = ({
  headline,
  simpleRichText,
  video,
}: VideoHeroProps) => {
  const videoSrc = video && video[0];
  return (
    <>
      <div className="py-20 min-h-[30rem] flex flex-col items-center justify-center bg-cover bg-center overflow-hidden relative mt-16">
        {videoSrc && (
          <div className="absolute inset-0 z-10">
            <video
              autoPlay
              loop
              playsInline
              muted
              style={{
                // fill the entire container
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                objectFit: "cover",
              }}
            >
              <source src={videoSrc.url!} />
            </video>
          </div>
        )}
        <div
          className="absolute inset-0 z-10"
          style={{
            backgroundImage:
              "linear-gradient(to bottom, rgba(0, 43, 72, 0.75), rgba(0, 43, 72, 0.9))",
          }}
        />
        <div className="relative z-10 max-w-3xl mx-auto px-10">
          <Headline
            component="h2"
            value={headline ?? ""}
            className="font-extrabold text-3xl md:text-5xl text-white text-center mb-4"
          />
          <div
            className="font-normal text-lg text-center max-w-2xl mx-auto mt-2 text-white"
            dangerouslySetInnerHTML={{ __html: simpleRichText ?? "" }}
          />
        </div>
      </div>
    </>
  );
};
