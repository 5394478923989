import { forwardRef } from "react";
import { cn } from "@utils/classNames";
import { PropsOf } from "types";

type AsProp<C extends React.ElementType> = {
  /**
   * An override of the default HTML tag.
   * Can also be another React component.
   */
  component?: C;
  children?: React.ReactNode;
};

export type ExtendableProps<
  ExtendedProps = {},
  OverrideProps = {}
> = OverrideProps & Omit<ExtendedProps, keyof OverrideProps>;

export type InheritableElementProps<
  C extends React.ElementType,
  Props = {}
> = ExtendableProps<PropsOf<C>, Props>;

export type PolymorphicComponentProps<
  C extends React.ElementType,
  Props = {}
> = InheritableElementProps<C, Props & AsProp<C>>;

export type PolymorphicRef<C extends React.ElementType> =
  React.ComponentPropsWithRef<C>["ref"];

interface BaseHeadlineProps {
  value: string;
}

export type HeadlineProps<C extends React.ElementType> =
  PolymorphicComponentProps<C, BaseHeadlineProps>;

export type HeadlineComponent = <C extends React.ElementType = "div">(
  props: HeadlineProps<C>
) => React.ReactElement | null;

const pipeRegex = /\|([^|]+)\|/g;
const dollarRegex = /\$([^$]+)\$/g;

const Headline: HeadlineComponent = forwardRef(function Headline<
  C extends React.ElementType = "div"
>(
  { className, component, value, ...props }: HeadlineProps<C>,
  ref: PolymorphicRef<C>
) {
  const Component = component || "div";

  const decoratedHeadline = value
    ?.replace(pipeRegex, '<span class="text-themecolor">$1</span>')
    ?.replace(
      dollarRegex,
      '<div class="relative inline-block bg-themecolor text-black">$1</div>'
    );

  return (
    <Component
      className={cn(className)}
      ref={ref}
      {...props}
      dangerouslySetInnerHTML={{
        __html: decoratedHeadline!,
      }}
    />
  );
});

export { Headline };
