import { Button } from "@components/ui/Button";
import { Headline } from "@components/Headline";
import { IconLoader } from "@tabler/icons-react";
import { trpc } from "@utils/trpc";
import { OppCardGridFragment } from "__types__/graphql";
import clsx from "clsx";
import Link from "next/link";
import React from "react";
import { BlueFlare } from "@components/BlueFlare";
import { cn } from "@utils/classNames";

interface OpportunityGridProps extends OppCardGridFragment {}

export const OpportunityGrid = (props: OpportunityGridProps) => {
  const { headline, miniBanner, componentSettings } = props;
  const hideOnMobile = componentSettings?.[0]?.hideOnMobile ?? false;
  const { data: opps, isLoading } = trpc.opportunity.list.useQuery({
    limit: "6",
  });

  const renderMiniBanner = () => {
    const [mini] = miniBanner;
    if (!mini) return null;

    return (
      <div className="max-w-4xl mx-auto mt-20">
        {mini.headline && (
          <h2 className="text-2xl md:text-4xl text-center font-medium md:leading-[1.3] bg-clip-text text-transparent bg-gradient-to-b from-neutral-50 to-neutral-300">
            {mini.headline}
          </h2>
        )}
        <div className="flex justify-center">
          <Button asChild size="xl" className="mt-8">
            <Link href={mini.action?.url!}>{mini.action?.customText}</Link>
          </Button>
        </div>
      </div>
    );
  };

  return (
    <div
      className={cn(
        "bg-neutral-900 relative pb-20 overflow-hidden px-8",
        hideOnMobile && "hidden sm:block"
      )}
    >
      <BlueFlare />
      <div className="relative max-w-5xl mx-auto">
        <Headline
          component="h1"
          value={headline ?? ""}
          className="text-2xl md:text-4xl text-center font-medium  md:leading-[1.3] bg-clip-text text-transparent bg-gradient-to-b from-neutral-50 to-neutral-300"
        />
        <p className="text-sm md:text-base max-w-3xl mx-auto text-center leading-normal text-gray-400 mt-4">
          Find the latest opportunities to work with the DoD on AI/ML, Data, and
          Digital projects.
        </p>
      </div>
      <div className="max-w-5xl mx-auto mt-12 grid grid-cols-1 md:grid-cols-3 gap-8">
        {opps?.length ? (
          <>
            {opps.map((entry, idx) => {
              const finalUrl = entry.id ? `/opportunities/${entry.id}` : null;
              const linkProps = finalUrl?.startsWith("http")
                ? { target: "_blank", rel: "noreferrer" }
                : {};
              return (
                <article
                  key={`opp-${idx}`}
                  className="flex flex-col p-8 border border-neutral-700 bg-neutral-800/50 rounded-lg relative group"
                >
                  <Link
                    href={finalUrl ?? "#"}
                    className="absolute inset-0 block cursor-pointer"
                    {...linkProps}
                  >
                    <span className="sr-only">{entry.opportunity_name}</span>
                  </Link>
                  <div className="flex flex-col flex-1 transition-transform duration-300 group-hover:scale-95 pointer-events-none">
                    <div className="flex flex-row space-x-2">
                      <p
                        className={clsx(
                          "font-extrabold capitalize",
                          entry.status.toLowerCase() === "open"
                            ? "text-green-600"
                            : "text-red-600"
                        )}
                      >
                        {entry.status}
                      </p>
                      {/* {entry.template &&
                        <p className="font-normal text-neutral-200">
                          {entry.template.name}
                        </p>
                      } */}
                    </div>
                    <div className="mt-2">
                      <h1 className="break-words font-extrabold text-neutral-100 text-xl md:text-3xl block mb-4 transition-all duration-100 group-hover:text-themecolor">
                        {entry.opportunity_name}
                      </h1>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: entry.summary ?? "",
                        }}
                        className="text-neutral-400 text-sm md:text-base line-clamp-4"
                      ></div>
                    </div>
                  </div>
                </article>
              );
            })}
          </>
        ) : isLoading ? (
          <IconLoader className="animate-spin mx-auto text-white my-10 col-start-2" />
        ) : (
          <div className="relative border border-neutral-700 p-8 rounded-md bg-neutral-800/50 z-10 h-full text-white md:col-span-2 lg:col-span-3 text-center">
            <p className="">No open opportunities. Please check back later.</p>
          </div>
        )}
      </div>
      {renderMiniBanner()}
    </div>
  );
};
