import { motion } from "framer-motion";
import Link from "next/link";
import { Button } from "./ui/Button";

interface CardBasic {
  actionText?: string;
  headline: string;
  id: string;
  uri: string;
  title: string;
}

export const CardBasic = ({ actionText, headline, id, uri, title }: CardBasic) => {
  return (
    <motion.article
      className="p-8 border border-neutral-700 mb-10 rounded-lg bg-neutral-800/50 backdrop-blur-sm shadow-lg group relative flex flex-col"
      whileHover={{
        y: -10,
      }}
    >
      <Link
        className="absolute inset-0"
        href={uri}
      >
        <span className="sr-only">{title}</span>
      </Link>

      <h1 className="font-extrabold text-xl text-neutral-50 group-hover:text-themecolor">
        {title}
      </h1>

      <p className="text-neutral-400 my-4">{headline}</p>

      {actionText &&
        <Button
          variant={`link`}
          className="pointer-events-none mt-auto"
          tabIndex={-1}
        >
          {actionText}
        </Button>
      }
    </motion.article>
  );
}