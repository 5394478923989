import { cn } from "@utils/classNames";
import React from "react";
import { cva, type VariantProps } from "class-variance-authority";

const inputVariants = cva(
  // anything global between variants,
  "w-full rounded-md py-2 px-3",
  {
    variants: {
      variant: {
        default:
          "flex h-10 border border-slate-300 placeholder:text-slate-400 focus:border-themecolor focus:ring focus:ring-themecolor focus:ring-opacity-50 disabled:cursor-not-allowed disabled:opacity-50",
        dark: "block border-0 bg-white/5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-themecolor sm:text-sm sm:leading-6",
      },
    },
    defaultVariants: {
      variant: "default",
    },
  }
);

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement>,
    VariantProps<typeof inputVariants> {
  asChild?: boolean;
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, variant, ...props }, ref) => {
    return (
      <input
        className={cn(inputVariants({ variant, className }))}
        ref={ref}
        {...props}
      />
    );
  }
);
Input.displayName = "Input";

export { Input, inputVariants };
