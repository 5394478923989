import { CardBasic } from "@components/CardBasic";
import {
  Actions_BlockType,
  BoxCalloutCards_BlockType,
  Components_BoxCalloutWithCards_Entry,
} from "__types__/graphql";
import { Actions } from "./Actions";
import { Headline } from "@components/Headline";
import { cn } from "@utils/classNames";

interface BoxCalloutWithCardsProps
  extends Components_BoxCalloutWithCards_Entry {
  actions: Actions_BlockType[];
  boxCalloutCards: BoxCalloutCards_BlockType[];
}

export const BoxCalloutWithCards = ({
  actions,
  boxCalloutCards,
  headline,
  simpleRichText,
  componentSettings,
}: BoxCalloutWithCardsProps) => {
  const hideOnMobile = componentSettings?.[0]?.hideOnMobile ?? false;

  return (
    <div
      className={cn(
        "w-full mx-auto max-w-7xl sm:px-6 lg:px-8",
        hideOnMobile && "hidden sm:block"
      )}
    >
      <div className="relative isolate overflow-hidden border border-gray-700 px-6 py-24 text-center shadow-2xl rounded-xl sm:px-16">
        <header className="flex flex-col items-center [&>*+*]:mt-6">
          {headline && (
            <Headline
              component="h2"
              value={headline}
              className="max-w-2xl text-3xl font-bold tracking-tight text-white sm:text-4xl"
            />
          )}
          {simpleRichText && (
            <div
              className="max-w-xl text-lg leading-8 text-gray-300"
              dangerouslySetInnerHTML={{
                __html: simpleRichText,
              }}
            />
          )}

          {actions.length > 0 && <Actions actions={actions} />}
        </header>

        {boxCalloutCards.length > 0 && (
          <div className="mt-20 grid lg:grid-cols-3 gap-x-6">
            {boxCalloutCards.map((card) => (
              <CardBasic
                key={card.id}
                id={card.id!}
                title={card.cardTitle!}
                headline={card.cardCopy!}
                actionText={card.cardAction?.customText!}
                uri={card.cardAction?.url!}
              />
            ))}
          </div>
        )}
        <svg
          viewBox="0 0 1024 1024"
          className="absolute left-1/2 top-1/2 -z-10 h-[64rem] w-[64rem] -translate-x-1/2 [mask-image:radial-gradient(closest-side,white,transparent)]"
          aria-hidden="true"
        >
          <circle
            cx={512}
            cy={512}
            r={512}
            fill="url(#827591b1-ce8c-4110-b064-7cb85a0b1217)"
            fillOpacity="0.7"
          />
          <defs>
            <radialGradient id="827591b1-ce8c-4110-b064-7cb85a0b1217">
              <stop stopColor="#7BD6FF" />
              <stop offset={1} stopColor="#036A99" />
            </radialGradient>
          </defs>
        </svg>
      </div>
    </div>
  );
};
